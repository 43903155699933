import './App.css';
import { Container, Button, Dialog, DialogTitle, Typography, DialogContentText, DialogActions, DialogContent } from "@mui/material";
import { useEffect, useState } from 'react';
import CookieConsent from "react-cookie-consent";
import { clarity } from 'react-microsoft-clarity';

// Start seeing data on the Clarity dashboard with your id
clarity.init("klandbdwqe");
clarity.consent(true);

function SignInModal(props) {
  const handleClose = () => {
    props.setSignInModalOpen(false)
  }
  // set a localStorage with the time of signin
  const unixtimestamp = Date.now() / 1000
  localStorage.setItem("signin", unixtimestamp)
  return (
    <>
      <DialogTitle>Come back at the end.</DialogTitle>
      <DialogContent>
        <DialogContentText>Come back at the end of your duty to sign out (you must use the same device and browser, so don't close this tab).</DialogContentText>
        <DialogContentText>IMPORTANT: If you do not sign out, you will not be marked present.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close this message</Button>
      </DialogActions>
    </>
  );
}

function SignOutModal(props){
  // create state for the response
  const [response, setResponse] = useState(0);  
  // create a useEffect to send the request
  useEffect(() => {
    // get the signin time from localStorage
    const signin = localStorage.getItem("signin")
    // get the key from the url
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('key');
    const id = urlParams.get('user');
    // send the POST request
    const body = {
      "signInTimestamp": signin,
      "id": id,
      "location": {
        "magicTag": "true"
      }
    }
    fetch('https://prefect-backend.ycat36.workers.dev/?command=magicMark&key=' + key, {
      method: 'POST',
      body: JSON.stringify(body)
    })
      .then(response => setResponse(response.status))
  }, []);
  if(response === 0){
    return (
      <>
        <DialogTitle>Talking with the server...</DialogTitle>
        <DialogContent>
          <DialogContentText>Signing out... (awaiting server response)</DialogContentText>
          <DialogContentText>If this message doesn't go away, please contact Isaac (with a screenshot of this).</DialogContentText>
          <DialogContentText>Make sure you are on Wi-Fi or your data is on.</DialogContentText>
        </DialogContent>
      </>
    )
  }else{
    return (
      <>
        <DialogTitle>Signing out</DialogTitle>
        <DialogContent>
          {response === 200 ? <DialogContentText>Success!</DialogContentText> : <DialogContentText>Something went wrong. Please contact Isaac or you may be signing out too early.</DialogContentText>}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Close this message</Button>
        </DialogActions>
      </>
    )
  }
}

function App() {
  // get key url param
  const urlParams = new URLSearchParams(window.location.search);
  const key = urlParams.get('key');
  const id = urlParams.get('user');
  // create state for the data from the API
  const [data, setData] = useState(0);
  // retrieve data from API
  useEffect(() => {
    fetch('https://prefect-backend.ycat36.workers.dev/?command=getMagicTag&key=' + key + '&id=' + id)
      .then(response => response.json())
      .then(data => setData(data));
  }, [id, key]);
  // create state for whether the modals are open
  const [ signInModalOpen, setSignInModalOpen ] = useState(false);
  const [ signOutModalOpen, setSignOutModalOpen ] = useState(false);
  // button handlers
  const handleSignIn = () => {
    setSignInModalOpen(true);
  }
  const handleSignOut = () => {
    setSignOutModalOpen(true);
  }
  // if data.person.sysFullName is undefined
  if(data.person?.sysFullName === undefined){
    return (
      <Typography>Loading</Typography>
    )
  }else{
    const duties = JSON.parse(data.group.duties)
    console.log(duties[0]["day"])
    // variables
    var signInAllowed = false
    var signOutAllowed = false
    var type = ""
    // get the current day of the week in form Mon, Tue, Wed, etc.
    // const day = new Date().toLocaleString('en-us', {  weekday: 'short' });
    // get the current hour and minute
    const hour = new Date().getHours();
    const minute = new Date().getMinutes();
    console.log(hour)
    console.log(minute)
    // if the time is before 10
    if(hour < 10){
      // allow sign in and out
      signInAllowed = true
      signOutAllowed = true
      type = "Meeting"
    }else{
      // if the time is between 11:05 and 11:18
      if(hour === 11 && minute >= 5 && minute <= 18){
        // allow sign in 
        signInAllowed = true
        type = "Duty"
      }else if(hour === 11 && minute >= 33){
        // allow sign out
        signOutAllowed = true
        type = "Duty"
      }
      // if the time is between 12:35 and 12:48
      if(hour === 12 && minute >= 35 && minute <= 48){
        // allow sign in 
        signInAllowed = true
        type = "Duty"
      }else if(hour === 13 && minute >= 3){
        // allow sign out
        signOutAllowed = true
        type = "Duty"
      }
    }
    return (
      <Container maxWidth="sm">
        <h1>Hi {data.person.sysFullName}!</h1>
        {signInAllowed ? <p>You can sign in now.</p> : <p>You can't sign at the moment.</p>}
        {signOutAllowed ? <p>You can sign out now.</p> : <p>You can't sign out at the moment (come back nearer the end if you signed in).</p>}
        {type !== "" ? <p>{type}</p> : null}
        {signInAllowed ?
        <Button variant="c1ontained" color="primary" onClick={handleSignIn}>
          Sign In
        </Button> : null}
        {signOutAllowed ?
        <Button variant="contained" color="secondary" onClick={handleSignOut}>
          Sign Out
        </Button> : null}
        <Dialog open={signInModalOpen}>
          <SignInModal key={key} setSignInModalOpen={setSignInModalOpen}/>
        </Dialog>
        <Dialog open={signOutModalOpen}>
          <SignOutModal key={key} id={id} handleClose={() => setSignOutModalOpen(false)}/>
        </Dialog>
        <h3>WARNING: This site is in beta (unstable), if you spot any issues send me (Isaac H) an email. If it all stops working, use the old system.</h3>
        <CookieConsent>By continuing to use the site and/or submitting attendance (and if you are doing so on behalf of someone else they agree as well), you acknowledge and agree to the legal documents (Privacy Policy, Terms and Conditions, Online Safety Bill documentation) and have no queries about the storage of your data. See the policy here: <a href="https://hts-rota-api.netlify.app/legal.pdf">Legal.pdf</a> and the use of Microsoft Clarity </CookieConsent>
      </Container>
    );
  }
}

export default App;
